<template>
    <div>
      <el-container>
        <el-main>
          <head-layout
            head-title="基础信息"
            :head-btn-options="headTopBtnOptions"
            @head-goback="handleHeadGoback"
            @head-save="headSave"
          >
            <template slot="titleSuffix">
                <span class="title_suffix" v-if="dataForm.isDangerous==1">超危</span>
            </template>
          </head-layout>
          <div style="padding:5px">
            <el-form :model="dataForm" ref="dataForm" :rules="rules" label-position="right" label-width="120px" :disabled="pageType=='view'">
                <el-row>
                    <el-col :span="span">
                        <el-form-item label="危大工程名称" prop="projectName">
                            <el-input v-model="dataForm.projectName" maxlength="64" placeholder="请填写危大工程名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item label="危大工程类别" prop="setTypeId">
                            <el-select v-model="dataForm.setTypeId" placeholder="请选择危大工程类别" @change="handleChangeSetType">
                                <el-option
                                    v-for="item in typeList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item label="危大工程概况" prop="projectOverview">
                            <el-input v-model="dataForm.projectOverview" :title="dataForm.projectOverview" maxlength="255" placeholder="请填写危大工程概况"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item label="类别描述" prop="setRemarIds">
                            <el-select v-model="dataForm.setRemarIds" multiple placeholder="请选择类别描述" @change="handleChangRemark" popper-class="custom-dropdown">
                                <el-option
                                    v-for="item in typeRemarkList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                    class="select-option-item"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item label="施工部位" prop="buildPartName">
                            <el-input v-model="dataForm.buildPartName" :title="dataForm.buildPartName" ref="buildPart" placeholder="请选择施工部位" @focus="handleChangeArea" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item label="计划时间" prop="planTime">
                            <el-date-picker v-model="dataForm.planTime" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item label="责任人" prop="respUserName">
                            <el-input v-model="dataForm.respUserName" :title="dataForm.respUserName"  ref="respUserName" placeholder="请选择责任人" @focus="handleChangeUser"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item label="施工时间" prop="buildTime">
                            <el-date-picker v-model="dataForm.buildTime" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="span">
                        <el-form-item label="责任分包单位" prop="respDeptName">
                            <el-input v-model="dataForm.respDeptName" :title="dataForm.respDeptName" ref="respDeptName" placeholder="请选择责任分包单位" maxlength="255" @focus="handleChangeDept"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
          </div>
        </el-main>
      </el-container>
      <el-container>
        <el-main>
          <head-layout head-title="危大工程资料" />
          <div>
            <el-form :model="dataForm" label-position="right" label-width="170px" :disabled="pageType == 'view'">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="安全技术交底" prop="skillInformationFlag">
                            <div class="files_box">
                                <div>
                                    <el-switch v-model="dataForm.skillInformationFlag" active-value='1' inactive-value='0'></el-switch>
                                    <span class="files_box_span">{{ dataForm.skillInformationFlag==1 ? '已完成' : '未完成' }}</span>
                                </div>
                                <el-upload
                                    v-if="pageType!=='view'"
                                    class="upload-demo"
                                    action="/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform"
                                    :on-success="
                                        (response, file, fileList) => {
                                            return uploadFileSuccess(response, file, fileList, 'skillInformation');
                                        }"
                                    :on-remove="
                                        (file, fileList) => {
                                            return handleFileRemove(file, fileList, 'skillInformation');
                                        }"
                                    multiple
                                    :file-list="skillInformation"
                                    :on-preview="handlePreview">
                                    <el-button size="small" type="primary">点击上传</el-button>
                                </el-upload>
                            </div>
                            <div v-if="pageType == 'view'" class="file-list-view">
                                <el-link v-for="item in skillInformation" :key="item.url" :underline="false" @click="handlePreview(item)" style="display:block">{{ item.name }}</el-link>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="安全专项施工方案及附件" prop="specialInformationFlag">
                            <div class="files_box">
                                <div>
                                    <el-switch v-model="dataForm.specialInformationFlag" active-value='1' inactive-value='0'></el-switch>
                                    <span class="files_box_span">{{ dataForm.specialInformationFlag==1 ? '已完成' : '未完成' }}</span>
                                </div>
                                <el-upload
                                    v-if="pageType!=='view'"
                                    class="upload-demo"
                                    action="/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform"
                                    :on-success="
                                        (response, file, fileList) => {
                                            return uploadFileSuccess(response, file, fileList, 'specialInformation');
                                        }"
                                    :on-remove="
                                        (file, fileList) => {
                                            return handleFileRemove(file, fileList, 'specialInformation');
                                        }"
                                    multiple
                                    :file-list="specialInformation"
                                    :on-preview="handlePreview">
                                    <el-button size="small" type="primary" v-if="pageType != 'view'">点击上传</el-button>
                                </el-upload>
                            </div>
                            <div v-if="pageType == 'view'" class="file-list-view">
                                <el-link v-for="item in specialInformation" :key="item.url" :underline="false" @click="handlePreview(item)" style="display:block">{{ item.name }}</el-link>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="dataForm.isDangerous==1">
                        <el-form-item label="专项方案专家论证" prop="skillDiscloseInformationFlag">
                            <div class="files_box">
                                <div>
                                    <el-switch v-model="dataForm.skillDiscloseInformationFlag" active-value='1' inactive-value='0'></el-switch>
                                    <span class="files_box_span">{{ dataForm.skillDiscloseInformationFlag==1 ? '已完成' : '未完成' }}</span>
                                </div>
                                <el-upload
                                    v-if="pageType!=='view'"
                                    class="upload-demo"
                                    action="/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform"
                                    :on-success="
                                        (response, file, fileList) => {
                                            return uploadFileSuccess(response, file, fileList, 'skillDiscloseInformation');
                                        }"
                                    :on-remove="
                                        (file, fileList) => {
                                            return handleFileRemove(file, fileList, 'skillDiscloseInformation');
                                        }"
                                    multiple
                                    :file-list="skillDiscloseInformation"
                                    :on-preview="handlePreview">
                                    <el-button size="small" type="primary" v-if="pageType != 'view'">点击上传</el-button>
                                </el-upload>
                            </div>
                            <div v-if="pageType == 'view'" class="file-list-view">
                                <el-link v-for="item in skillDiscloseInformation" :key="item.url" :underline="false" @click="handlePreview(item)" style="display:block">{{ item.name }}</el-link>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="专项施工方案交底" prop="constructionInformationFlag">
                            <div class="files_box">
                                <div>
                                    <el-switch v-model="dataForm.constructionInformationFlag" active-value='1' inactive-value='0'></el-switch>
                                    <span class="files_box_span">{{ dataForm.constructionInformationFlag==1 ? '已完成' : '未完成' }}</span>
                                </div>
                                <el-upload
                                v-if="pageType!=='view'"
                                    class="upload-demo"
                                    action="/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform"
                                    :on-success="
                                        (response, file, fileList) => {
                                            return uploadFileSuccess(response, file, fileList, 'constructionInformation');
                                        }"
                                    :on-remove="
                                        (file, fileList) => {
                                            return handleFileRemove(file, fileList, 'constructionInformation');
                                        }"
                                    multiple
                                    :file-list="constructionInformation"
                                    :on-preview="handlePreview">
                                    <el-button size="small" type="primary" v-if="pageType != 'view'">点击上传</el-button>
                                </el-upload>
                            </div>
                            <div v-if="pageType == 'view'" class="file-list-view">
                                <el-link v-for="item in constructionInformation" :key="item.url" :underline="false" @click="handlePreview(item)" style="display:block">{{ item.name }}</el-link>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
          </div>
        </el-main>
      </el-container>

      <!-- 施工部位（项目区域） -->
      <el-dialog title="项目区域选择" :visible.sync="areaDialog" width="80%">
        <prjArea ref="prjArea"  @prjAreaCallback="prjAreaCallback" dialogType />
      </el-dialog>
      <!-- 人员选择 -->
      <el-dialog title="人员选择" :visible.sync="userShow" width="80%" top="8vh">
        <rectifyDataOrg ref="rectifyDataOrg" @callback="selectUserData" multiple :prjId=prjId /> 

      </el-dialog>
      <!-- 责任部门 -->
       <el-dialog title="部门选择" :visible.sync="deptShow" width="80%">
        <DeptDialog ref="DeptDialog" :parentDeptId="prjId" @select-all-data="selectDept" :selection="true"></DeptDialog>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import FormLayout from "@/views/components/layout/form-layout";
  import HeadLayout from "@/views/components/layout/head-layout.vue";
  import GridLayout from "@/views/components/layout/grid-layout.vue";
  import { mapGetters } from "vuex";
  import pdf from "@/components/pdf/pdf.vue";
  import * as STANDARDAPI from '@/api/safetyTtandard/dangerousProject'
  import prjArea from '@/views/business/safe/itemList/projectArea/index.vue';
  import rectifyDataOrg from '@/views/hiddenTrouble/riskItemView/checklist/rectifyDataOrg.vue'
//   import ProjectSelectUser from "@/views/components/UserDeptDialog/projectSelectUser";
  import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog";
  import { downloadFileBlob } from "@/util/util";

  import * as API from '@/api/safe/dangerousProjectList'
  
  export default {
    components: {
      FormLayout,
      HeadLayout,
      GridLayout,
      prjArea,
      rectifyDataOrg,
      DeptDialog,
    },
  
    data() {
      return {
        nodeData: {},
        pageType: "",
        span: 12,
        dataForm: {
            projectName: '',
            setTypeId: '',
            projectOverview: '',
            setRemarIds: [],
            buildPartName: '',
            buildPart: '',
            planStartTime: '',
            planEndTime: '',
            respUserId: '',
            respUserName: '',
            buildStartTime: '',
            buildEndTime: '',
            respDeptId: '',
            respDeptName: '',
            skillInformation: '',
            skillInformationFlag: '',
            specialInformation: '',
            specialInformationFlag: '',
            constructionInformation: '',
            constructionInformationFlag: '',
            organizationId: '',
            organizationName: '',
        },
        rules: {
            projectName: [{required: true, message: '请填写危大工程名称', trigger: 'blur'}],
            setTypeId: [{required: true, message: '请选择危大工程类别', trigger: 'change'}],
            setRemarIds: [{required: true, message: '请选择类别描述', trigger: 'change'}],
            buildTime: [{required: true, message: '请选择施工时间', trigger: 'change'}],
        },
        typeList: [], // 
        typeRemarkList: [],
        areaDialog: false,
        userShow: false,
        deptShow:false,
        prjId: '',
        skillInformation: [], // 安全交底附件
        specialInformation: [], // 安全专项施工方案及附件
        constructionInformation: [], // 专项施工方案交底资料	
      };
    },
  
    created() {
      this.nodeData = JSON.parse(this.$route.query.row);
      this.prjId = this.nodeData.prjId || ''
      this.pageType = this.$route.query.type;
      this.getTypeList()
      if(this.pageType == 'add') {
        this.$set(this.dataForm, 'planTime', [])
        this.$set(this.dataForm, 'buildTime', [])
        this.$set(this.dataForm, 'organizationId', this.nodeData.prjId)
        this.$set(this.dataForm, 'organizationName', this.nodeData.prjName)
      } else if(this.nodeData.id){
        this.getDetail(this.nodeData.id)
      }
    },
  
    computed: {
      ...mapGetters(["permission", "colorName"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.dangerousProjectList_add, true),
          viewBtn: this.vaildData(this.permission.dangerousProjectList_view, true),
          editBtn: this.vaildData(this.permission.dangerousProjectList_edit, true),
          delBtn: this.vaildData(this.permission.dangerousProjectList_delete, true),
        };
      },
      headTopBtnOptions() {
        let buttonBtn = []
        if(this.pageType !=='view'){
            buttonBtn.push(
                {
                    label: "保存",
                    emit: "head-save",
                    type: "button",
                    icon: "",
                }
            )
        }
        buttonBtn.push(
            {
                label: "返回",
                emit: "head-goback",
                type: "button",
                icon: "",
            }
        )
        return buttonBtn;
      },
    },
  
    methods: {
      handleHeadGoback() {
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      },

      getTypeList () {
        STANDARDAPI.list({projectType: 1}).then((res) => {
          this.typeList = res.data.data;
        });
      },

      handleChangeSetType (value) {
        this.dataForm.setRemarIds = []
        STANDARDAPI.list({projectType: 2,parentId: value}).then((res) => {
          this.typeRemarkList = res.data.data;
        });
      },

      handleChangRemark (value) {
        let arr = this.typeRemarkList.filter(val => value.includes(val.id)&&val.isDangerous==1)
        this.dataForm.isDangerous = arr.length==0 ? '0' : '1'
      },

      getDetail (id) {
        API.detail(id).then(res => {
            console.log(res)
            let data = res.data.data
            this.dataForm = {
                ...res.data.data,
                planTime: data.planStartTime ? [data.planStartTime, data.planEndTime] : [],
                buildTime: data.buildStartTime ? [data.buildStartTime, data.buildEndTime] : [],
                setRemarIds: data.setRemarIds.split(','),
            }
            this.nodeData.prjId = data.organizationId
            this.prjId = data.organizationId
            this.skillInformation = data.skillInformation ? JSON.parse(data.skillInformation) : []
            this.specialInformation = data.specialInformation ? JSON.parse(data.specialInformation) : []
            this.constructionInformation = data.constructionInformation ? JSON.parse(data.constructionInformation) : []
            this.skillDiscloseInformation = data.skillDiscloseInformation ? JSON.parse(data.skillDiscloseInformation) : []
            STANDARDAPI.list({projectType: 2,parentId: data.setTypeId}).then((res) => {
                this.typeRemarkList = res.data.data; 
            }); 

        })
      }, 

      handleChangeArea(){
        this.$refs.buildPart.blur()
        this.areaDialog = true
        this.$nextTick(()=>{
            this.$refs.prjArea.init(this.nodeData.prjId,'multiple')
        })
      },

      prjAreaCallback(data){
        this.dataForm.buildPart = data.map(val=>val.id).toString()
        this.dataForm.buildPartName = data.map(val=>val.areaName).toString()
        this.areaDialog = false
      },

      handleChangeUser () {
        this.$refs.respUserName.blur()
          this.userShow = true
      },

      selectUserData (data) {
        let isUsedArr = data.filter(val=>val.isUsed != 0)
        if(isUsedArr.length == 0){
            this.dataForm.respUserId = data.map(val=> val.userId).toString()
            this.dataForm.respUserName = data.map(val=> val.userName).toString()
          this.userShow = false
        } else {
          this.$message.warning('只可选择已入场人员')
        }
      },

      handleChangeDept() {
          this.$refs.respDeptName.blur()
          this.deptShow = true
      },

      selectDept (data) {
        this.dataForm.respDeptId = data.map(val=>val.id).toString() 
        this.dataForm.respDeptName = data.map(val=>val.deptName).toString() 
        this.deptShow = false
      },

      // 上传成功
        uploadFileSuccess(response, file, fileList, fieldName) {
            // fieldName 表单中向后端传递的字段名
            this[fieldName] = fileList.map((item) => {
                if (item.response) {
                    item.response.data.url = item.response.data.link;  
                    item.response.data.name = item.response.data.originalName
                    return {
                        url: item.response.data.link,
                        name: item.response.data.originalName
                    };
                } else {
                    return {
                        url: item.url,
                        name: item.name
                    };
                }
            });
            console.log(this[fieldName])
            this.dataForm[fieldName] = JSON.stringify(this[fieldName])
        },
        // 删除文件
        handleFileRemove(file, fileList, fieldName) {
            this[fieldName] = fileList.map((item) => {
                if (item.response) {
                    return item.response.data;
                } else {
                    return item;
                }
            });
            this.dataForm[fieldName] = JSON.stringify(this[fieldName])
        },

        handlePreview (file) {
            console.log(file)
            if (file.url) {
                downloadFileBlob(file.url, file.name);
            }
        },

        headSave () {
            this.$refs.dataForm.validate(valid =>{
                if(valid) {
                    console.log(this.dataForm)
                    let params = {
                        ...this.dataForm,
                        planStartTime: this.dataForm.planTime ? this.dataForm.planTime[0]||'' : '',
                        planEndTime: this.dataForm.planTime ? this.dataForm.planTime[1]||'' : '',
                        buildStartTime: this.dataForm.buildTime[0],
                        buildEndTime: this.dataForm.buildTime[1],
                        setRemarIds: this.dataForm.setRemarIds.toString() 
                    }
                    delete params.planTime
                    delete params.buildTime
                    console.log(params)
                    API.submit(params).then(res => {
                        this.dataForm.id = res.data.data
                        this.$message.success("操作成功")
                    })
                }
            })
        }
  
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .files_box{
    display: flex;
    padding: 8px 5px;
    // .el-switch{
    //     margin-right: 10px;
    // }
    .files_box_span{
        margin: 0 10px
    }
  }
  .title_suffix{
    background-color: #C5211B;
    padding: 2px 5px;
    color: #fff;
    margin-left: 5px;
  }
  .custom-dropdown .el-scrollbar__view {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .custom-dropdown .el-select-dropdown__item {
        max-width: 500px; /* 设置下拉项的宽度 */
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ::v-deep .el-divider__text, .el-link {
        font-size: 14px !important;
    }
    ::v-deep .el-upload-list__item.is-success.focusing .el-icon-close-tip{
        display: none !important;
    }
  </style>
